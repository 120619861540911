import React, { useState, useEffect } from "react"
import { Link } from "gatsby"  // <Link to="/page-2/">Go to page 2</Link>

/* import Layout from "../components/layout" //<Layout></Layout>
import Image from "../components/image" //<Image filename="gatsby-astronaut.png" /> */
import SEO from "../components/seo"  //<SEO title="Home" />
import classnames from 'classnames';
import './index.css';

import logo from '../images/logo.svg';
import phoneConcept from '../images/phoneConcept.svg';
import HIW1 from '../images/HIW1.svg';
import HIW2 from '../images/HIW2.svg';
import HIW3 from '../images/HIW3.svg';
import HIW4 from '../images/HIW4.svg';
import WIIB1 from '../images/WIIBPhone1.svg';
import WIIB2 from '../images/WIIBPhone2.svg';
import WIIB3 from '../images/WIIBPhone3.svg';
import WIIB4 from '../images/WIIBPhone4.svg';
import WIIB5 from '../images/WIIBPhone5.svg';
import Cases1 from '../images/Cases1.svg';
import Cases2 from '../images/Cases2.svg';
import Cases3 from '../images/Cases3.svg';
import Cases4 from '../images/Cases4.svg';
import Cases5 from '../images/Cases5.svg';
import Cases6 from '../images/Cases6.svg';
import mailIcon from '../images/mailIcon.svg';
import locationIcon from '../images/locationIcon.svg';

const IndexPage = () => {
    const [scrolled, setScrolled] = useState(false);

    // change state on scroll
    useEffect(() => {
        const handleScroll = () => {
            const isScrolled = window.scrollY > window.innerHeight;
            if (isScrolled !== scrolled) {
                setScrolled(!scrolled);
            }
        };

        document.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            // clean up the event handler when the component unmounts
            document.removeEventListener('scroll', handleScroll);
        };
    }, [scrolled]);

    return (
        <div>
            <SEO title="Home" />
            <div className='App'>

                <header className='App-header'>
                    {!scrolled &&
                        <div className='App-header-titleGroup'>
                            <img src={logo} className='App-logo' alt='logo' />
                            <span>Where the car<br />comes to you</span>
                        </div>
                    }
                </header>

                <div className='App-body'>

                    <div className='sectionWhite'>
                        <section>
                            <h1 className='phoneConceptTitle'>Rummo is the fastest way to rent a car</h1>
                            <div className={classnames('row', 'phoneConceptRow')}>
                                <div className={classnames('col', 'spaceEvenly', 'paragraphColumnLeft')}>
                                    <h1 className='phoneConceptParagraphTitle'>Rummo is the fastest way to rent a car</h1>
                                    <p className='phoneConceptParagraph'>It takes on average +75 minutes to rent a car. <strong>Say goodbye to usual time-wasters.</strong></p>
                                    <p className='phoneConceptParagraph'><strong>No more transit to a pickup counter</strong>, paperwork, nor waiting in lines.</p>
                                    <p className='phoneConceptParagraph'>Choose a car.<br />When the time comes, <strong>your car will be delivered to you.</strong></p>
                                </div>
                                <div className='col'>
                                    <img src={phoneConcept} className='phoneConceptImg' alt='appView' />
                                </div>
                                <div className={classnames('col', 'spaceEvenly', 'paragraphColumnRight')}>
                                    <p className='phoneConceptParagraph'><strong>No more transit to a pickup counter</strong>, paperwork, nor waiting in lines.</p>
                                    <p className='phoneConceptParagraph'>Choose a car.<br />When the time comes, <strong>your car will be delivered to you.</strong></p>
                                </div>
                            </div>
                        </section>
                    </div>

                    <div className='sectionGray'>
                        <section className='sectionGray'>
                            <h1 className='conceptTitle'>How it works?</h1>
                            <div className='conceptRow'>
                                <div className='col'>
                                    <img src={HIW1} className='hiwImgMarginRight' alt='howItWorksImage' />
                                </div>
                                <div className={classnames('col', 'spaceEvenly')}>
                                    <h2>Book a car using our app</h2>
                                    <p>Download our app and book a car when you need it. No lines or paperwork.</p>
                                </div>
                            </div>
                            <div className='conceptRow'>
                                <div className={classnames('col', 'spaceEvenly')}>
                                    <h2>We deliver it to you</h2>
                                    <p>A driver will bring it, right to you anywhere, on demand. No keys, all you need is your phone.</p>
                                </div>
                                <div className='col'>
                                    <img src={HIW2} className='hiwImgMarginLeft' alt='howItWorksImage' />
                                </div>
                            </div>
                            <div className='conceptRow'>
                                <div className='col'>
                                    <img src={HIW3} className='hiwImgMarginRight' alt='howItWorksImage' />
                                </div>
                                <div className={classnames('col', 'spaceEvenly')}>
                                    <h2>What happens to the driver?</h2>
                                    <p>They magically disappear.</p>
                                </div>
                            </div>
                            <div className='conceptRow'>
                                <div className={classnames('col', 'spaceEvenly')}>
                                    <h2>When you’re done using it</h2>
                                    <p>Just park it anywhere within a serviced city or a driver will come and pick it up.<br />It’s really that easy.</p>
                                </div>
                                <div className='col'>
                                    <img src={HIW4} className='hiwImgMarginLeft' alt='howItWorksImage' />
                                </div>
                            </div>
                        </section>
                    </div>

                    <div className='sectionWhite'>
                        <section>
                            <h1 className='WIIBTitle'>Why is it better?</h1>
                            <div className={classnames('row', 'spaceBetween', 'WIIBRowBottomMargin')}>
                                <div className='col'>
                                    <h2 className='WIIBSubtitle'>Transparent</h2>
                                    <p className='WIIBParagraph'>No hidden fees or deposits.</p>
                                </div>
                                <div className='col'>
                                    <img src={WIIB1} alt='howItWorksImage' />
                                </div>
                            </div>
                            <div className={classnames('row', 'spaceBetween', 'WIIBRowBottomMargin')}>
                                <div className='col'>
                                    <h2 className='WIIBSubtitle'>Affordable</h2>
                                    <p className='WIIBParagraph'>Better rates than tradicional<br />rent-a-cars and cheaper than riding an uber.</p>
                                </div>
                                <div className='col'>
                                    <img src={WIIB2} alt='howItWorksImage' />
                                </div>
                            </div>
                            <div className={classnames('row', 'spaceBetween', 'WIIBRowBottomMargin')}>
                                <div className='col'>
                                    <h2 className='WIIBSubtitle'>Convenient</h2>
                                    <p className='WIIBParagraph'>Fast sign up and instant verification. We bring the car right to you.</p>
                                </div>
                                <div className='col'>
                                    <img src={WIIB3} alt='howItWorksImage' />
                                </div>
                            </div>
                            <div className={classnames('row', 'spaceBetween', 'WIIBRowBottomMargin')}>
                                <div className='col'>
                                    <h2 className='WIIBSubtitle'>Flexible</h2>
                                    <p className='WIIBParagraph'>Unlimited miles, extend anytime.<br />Pay-as-you-go or get a fixed fee by lock in dates.</p>
                                </div>
                                <div className='col'>
                                    <img src={WIIB4} alt='howItWorksImage' />
                                </div>
                            </div>
                            <div className={classnames('row', 'spaceBetween', 'WIIBRowBottomMargin')}>
                                <div className='col'>
                                    <h2 className='WIIBSubtitle'>Easy</h2>
                                    <p className='WIIBParagraph'>When it’s time to turn in your car, we’ll pick it up from you wherever it’s convenient – home, office, whatever.</p>
                                </div>
                                <div className='col'>
                                    <img src={WIIB5} alt='howItWorksImage' />
                                </div>
                            </div>
                        </section>
                    </div>

                    <header className='casesHeader'>
                        <h1 className='textCenter' style={{ color: 'white' }}>Just for holidays.<br />And work trips.<br />And everything else.
                    </h1>
                    </header>

                    <div className='sectionWhite'>
                        <section>
                            <div className={classnames('row', 'casesRow')}>
                                <div className={classnames('col', 'casesCard')}>
                                    <img src={Cases1} alt='casesImage' style={{ 'marginTop': '15%' }} />
                                    <span>Use during holidays</span>
                                </div>
                                <div className={classnames('col', 'casesCard')}>
                                    <img src={Cases2} alt='casesImage' style={{ 'marginTop': '13%' }} />
                                    <span>Work trips</span>
                                </div>
                                <div className={classnames('col', 'casesCard', 'casesCardShow')}>
                                    <img src={Cases3} alt='casesImage' style={{ 'marginTop': '10%' }} />
                                    <span>Long trip visiting<br />relatives</span>
                                </div>
                            </div>
                            <div className={classnames('row', 'casesRow')}>
                                <div className={classnames('col', 'casesCard', 'casesCardNotShow')}>
                                    <img src={Cases3} alt='casesImage' style={{ 'marginTop': '10%' }} />
                                    <span>Long trip visiting<br />relatives</span>
                                </div>
                                <div className={classnames('col', 'casesCard')}>
                                    <img src={Cases4} alt='casesImage' style={{ 'marginTop': '15%' }} />
                                    <span>Daily commute<br />(subscription model)</span>
                                </div>
                                <div className={classnames('col', 'casesCard', 'casesCardShow')}>
                                    <img src={Cases5} alt='casesImage' style={{ 'marginTop': '15%' }} />
                                    <span>Move to a new home</span>
                                </div>
                                <div className={classnames('col', 'casesCard', 'casesCardShow')}>
                                    <img src={Cases6} alt='casesImage' style={{ 'marginTop': '11%' }} />
                                    <span>To move while car is<br />at the shop / inspection</span>
                                </div>
                            </div>
                        </section>
                    </div>

                    <div className='sectionGray'>
                        <section>
                            <h1 className='textCenter'>Where we service?</h1>
                            <p className={classnames('textCenter', 'serviceParagraph')}>We are currently setting up operations in <strong>New York.</strong> And we are expanding fast.<br /><strong>We’ll tell you when we arrive to your city.</strong></p>
                            <div className={classnames('col', 'serviceForm')}>
                                <div className={classnames('col', 'serviceFormInputCol')}>
                                    <div className='row'>
                                        <img src={mailIcon} alt='mailIcon' />
                                        <input type='text' id='fname' name='fname' placeholder='your.email@address.com' />
                                    </div>
                                    <div className='row'>
                                        <img src={locationIcon} alt='mailIcon' />
                                        <input type='text' id='fname' name='fname' placeholder='your city' />
                                    </div>
                                </div>
                                <div className='button' onClick={() => this.setState({ formSubmited: true })}>
                                    <span>Let me know</span>
                                </div>
                            </div>
                        </section>
                    </div>

                    <div className='sectionWhite'>
                        <section>
                            <h1 className='textCenter'>Make money driving</h1>
                            <p className={classnames('textCenter', 'serviceParagraph')}>Be your own boss and set your own schedule. <strong>Delivery providers don’t need to own a car to move cars around the city,</strong> eliminating common expenses like gas, insurance, maintenance, and vehicle depreciation.</p>
                            <div className={classnames('col', 'serviceForm')}>
                                <div className='button' onClick={() => window.open("mailto:hello@rummo.to?subject=I want to know how to deliver Rummo cars&body=Hi. I would like to know more about Rummo's drivers program and its benefits. Thank you.")}>
                                    <span>Start delivering</span>
                                </div>
                            </div>
                        </section>
                    </div>

                    <div className='sectionGray'>
                        <section>
                            <h1 className='textCenter'>Become a fleet partner</h1>
                            <p className={classnames('textCenter', 'serviceParagraph')}>Are you a large fleet owner, car dealership, or rental company? Partner with Rummo to drive reservations to your fleet. <strong>You provide the cars, we take care of everything else.</strong></p>
                            <div className={classnames('col', 'serviceForm')}>
                                <div className='button' onClick={() => window.open("mailto:hello@rummo.to?subject=Let's partner up&body=Hi. I would like to know more about Rummo's partner program. Thank you.")}>
                                    <span>Partner with us</span>
                                </div>
                            </div>
                        </section>
                    </div>

                    <div className='sectionBlack'>
                        <section className='footerSection'>
                            <img src={logo} className='App-logo-footer' alt='logo' />
                            <div className='row'>
                                <div className={classnames('col', 'footerCol')}>
                                    <span className='footerTitle' onClick={() => window.open("mailto:hello@rummo.to?subject=Let's partner up&body=Hi. I would like to know more about Rummo's partner program. Thank you.")}>
                                        Become a partner
                                </span>
                                    <span className='footerBody'>
                                        Keep your fleet utilized and acquire new customers with Rummo
                                </span>
                                </div>
                                <div className={classnames('col', 'footerCol')}>
                                    <Link to="/careers/" className='footerTitle'>Careers</Link>
                                    <span className='footerBody'>
                                        Work at one of the fastest growing companies in the sector.
                                </span>
                                </div>
                            </div>
                            <span className='footerCopyright'>© 2020 Rummo</span>
                        </section>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default IndexPage
